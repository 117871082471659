import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Article from "../containers/Content/Article"
import RichtextRenderer from "../components/RichtextRenderer"

const Content = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    title,
    subHeading,
    pageContent,
  } = data.content
  return (
    <Layout>
      <Seo title={ogTitle} description={ogDescription} />
      <Banner title={title} slogan={subHeading} />
      <Article>
        <div className="blog-text">
          <RichtextRenderer data={pageContent} />
        </div>
      </Article>
      <div className="xl:mb-36" />
    </Layout>
  )
}

export const query = graphql`
  query ContentPageQuery($slug: String) {
    content: contentfulContentPageTemplate(slug: { eq: $slug }) {
      ogTitle
      ogDescription
      title
      subHeading
      pageContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData(width: 950, quality: 100, placeholder: BLURRED)
          }
          ... on ContentfulQuotes {
            contentful_id
            __typename
            author
            remarks {
              remarks
            }
          }
          ... on ContentfulDownloadableMediaModel {
            contentful_id
            title
            media {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Content
